import React from "react"
import Header from "./Header"
import useSiteMetadata from "../static_queries/useSiteMetadata"
import layoutStyles from "../styles/components/layout.module.scss"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Layout(props) {
  const { title } = useSiteMetadata()
  return (
    <section
      className={`${layoutStyles.layout} ${
        props.page === "info" && 
        layoutStyles.info_page}`}
      style={{
        backgroundColor: props.bgColor,
      }}
    >
      <Header page={props.page} title={title} />
      <div className={layoutStyles.content}>{props.children}</div>
      <ToastContainer />
    </section>
  )
}
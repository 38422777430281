import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import default_image from '../images/favicon.png'

const key = (item) => "name" in item ? item.name : item.property

const removeDoubles = (arr) => {
  const index = {}
  const ret = []
  const { length } = arr
  let i = 0;
  while(i < length){
    const item = arr[i++]
    if(!item){ continue }
    const name = key(item)
    if(index[name]){ continue; }
    index[name] = true
    ret.push(item)
  }
  return ret
}

const SEO = ({ description = '', image, lang = 'en', meta = [], title: page_title }) => {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            description
            url
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage = image || default_image
  const title = page_title ? `${page_title} | ${site.siteMetadata.title}` : site.siteMetadata.title

  const _meta = removeDoubles([
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:image`,
      content: metaImage,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ].concat(meta))

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={_meta}
    >
    </Helmet>
  )
}

export default SEO
